<template>
  <section class="helper container flex flex-col mx-auto py-12 lg:py-24 px-6 2xl:max-w-8xl relative">
    <div
      class="absolute top-[-22px] lg:top-[-43px] h-[207px] lg:h-[414px] w-[40vw] lg:w-[575px] 2xl:w-[675px] right-0 lg:right-[-175px] 2xl:right-[-375px] z-10 pointer-events-none detail"
    >
      <div></div>
    </div>
    <hgroup class="max-lg:mt-8 relative z-10">
      <h2 class="text-6xl lg:text-8xl leading-none font-bold max-lg:mb-8" v-html="helper.title" />
      <h3 class="text-2xl" v-html="helper.subtitle" />
    </hgroup>
    <Button v-if="helper.cta.show" :href="helper.cta.url" :target="helper.cta.target" class="mt-12 mr-auto z-10">
      <i v-if="helper.cta.icon" class="!text-2xl mr-2" :class="helper.cta.icon" />
      {{ helper.cta.label }}
    </Button>
  </section>
</template>

<script setup>
import { computed } from "vue";

import Button from "@/components/Buttons/Button/v2/Button.vue";

const props = defineProps({
  helper: {
    type: Object,
    default: () => ({}),
  },
});
const helper = computed(() => props.helper);
</script>

<style lang="scss" scoped>
@import "./../../../styles/themes/tailwind/tailwind-light/variables";

.detail {
  @media screen and (max-width: 1024px) {
    overflow: hidden;
  }
  > div,
  &:before,
  &:after {
    content: "";
    display: block;
    background-color: $primaryColor;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    position: absolute;
    width: 100%;
    height: 62px;
    @media screen and (min-width: 1024px) {
      height: 125px;
    }
  }
  > div {
    top: 0;
    left: 85px;
  }
  &:before {
    top: 72px;
    @media screen and (min-width: 1024px) {
      top: 145px;
    }
  }
  &:after {
    bottom: 0;
    left: 85px;
  }
}
</style>
